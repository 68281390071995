<template>
  <div>
    <ApolloQuery
      :query="require('../../graphql/MigrationInfo/AllMigrationInfo.gql')"
      :variables="{
        cursor: ''
      }"
      :fetchPolicy="'cache-and-network'"
      clientId="apolloClientCached"
      notifyOnNetworkStatusChange
    >
      <template v-slot="{ result: { error, data }, query, isLoading }">
        <span v-if="data && isLoading" class="m-2 float-right">
          Actualizando lista de Migraciones...
        </span>
        <b-table-simple
          v-if="data && data.allMigrationInfo"
          hover=""
          responsive
        >
          <b-thead>
            <b-tr>
              <b-th>Fecha Inicio Migración</b-th>
              <b-th>Fecha Termino Migración</b-th>
              <b-th>Empresa</b-th>
              <b-th></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-if="!data.allMigrationInfo.edges.length">
              <b-td colspan="7">No hay migraciones registradas</b-td>
            </b-tr>
            <migration-info-detail
              v-for="migration of data.allMigrationInfo.edges"
              :key="migration.node.id"
              :migration="migration.node"
              @delete-migration="error => deleteMigrationInfo(query, error)"
            />
          </b-tbody>
        </b-table-simple>
        <b-alert v-if="error" show="" variant="danger">
          {{ error.message ? error.message : "Ha ocurrido un error" }}
        </b-alert>
        <b-spinner
          v-if="isLoading"
          label="Spinning"
          class="m-2 float-right"
        ></b-spinner>
        <div v-else>
          <b-button
            v-if="hasNextPage(data)"
            class="m-2 float-right"
            @click="seeMore(query, data.allMigrationInfo.pageInfo.endCursor)"
          >
            Ver más
          </b-button>
          <b-alert v-else show variant="light" class="text-center">
            No hay más datos para mostrar.
          </b-alert>
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>

<script>
import MigrationInfoDetail from "./MigrationInfoDetail.vue";
export default {
  name: "MigrationInfoList",
  props: {
    migration: Object
  },
  components: {
    MigrationInfoDetail
  },
  data() {
    return {
      showAlert: false,
      error: false,
      errorMessage: null
    };
  },
  methods: {
    /**
     * Indica si hay mas elementos para cargar o no
     * @return {Boolean}
     */
    hasNextPage(data) {
      if (data?.allMigrationInfo?.edges?.length < 50) {
        return false;
      }
      return data?.allMigrationInfo?.pageInfo?.endCursor != null;
    },
    /**
     * Trae más resultados de una query avanzando en su cursor.
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      await query.fetchMore({
        variables: {
          cursor: cursor
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const updated = Object.assign({}, this.$dup(prev));
          updated.allMigrationInfo.pageInfo =
            fetchMoreResult.allMigrationInfo.pageInfo;
          if (fetchMoreResult?.allMigrationInfo?.edges?.length !== 0) {
            updated.allMigrationInfo.edges.push(
              ...fetchMoreResult.allMigrationInfo.edges
            );
          }
          return updated;
        }
      });
    },
    /**
     * Recibe el evento de eliminacion de una migración
     * solo si no hay algun error, si lo hay lo muestra
     * @param {Object} query - query de allMigrationInfo
     * @param {String} error - mensaje de error
     */
    deleteMigrationInfo(query, error = null) {
      this.softReset();
      if (error) {
        this.error = true;
        this.errorMessage = error;
      } else {
        query.refetch();
      }
      this.showAlert = true;
    },
    /**
     * Se encarga de resetear las variables que indican el error o exito
     */
    softReset() {
      this.error = false;
      this.errorMessage = null;
    }
  }
};
</script>

<style></style>
