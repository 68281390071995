<template>
  <div>
    <base-alert-with-count-down
      v-model="showAlert"
      :alert-variant="alertVariant"
      :dismiss-secs="error ? 10 : 5"
    >
      {{ alertMessage }}</base-alert-with-count-down
    >
    <b-row>
      <b-col xl="10" cols="12">
        <b-row>
          <b-col md="6" sm="12" cols="12">
            Fecha de Inicio Migración:
            <b-form-datepicker
              :loading="isLoadingMigration"
              v-model="dateStartMigration"
              :invalid-feedback="validation.dateStartMigration.invalidFeedback"
              :state="validation.dateStartMigration.valid"
            />
          </b-col>
          <b-col md="6" sm="6" cols="12">
            Fecha de Termino Migración:
            <b-form-datepicker
              :loading="isLoadingMigration"
              v-model="dateEndMigration"
              :invalid-feedback="validation.dateEndMigration.invalidFeedback"
              :state="validation.dateEndMigration.valid"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12" sm="12" cols="12">
            <input-with-skeleton
              :loading="isLoadingMigration"
              label="Comentarios"
              v-model="comments"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12" sm="12" cols="12">
            <b-form-group label="Empresa">
              <base-live-select
                :loading="isLoadingMigration"
                v-model="company"
                id="company"
                placeholder="Seleccionar"
                :clearable="false"
                :disabled="isConsolidated()"
                @search="updateCompanies"
                :min-search-characters="2"
                :debounce="750"
              ></base-live-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col xl="2" cols="12">
        <b-button
          variant="info"
          :disabled="!changed || saving"
          :title="changed ? '' : 'No hay cambios'"
          @click="save"
          class="mb-3 w-100"
        >
          <span v-if="saving">
            Guardando
            <b-spinner label="Spinning" />
          </span>
          <span v-else>Guardar</span>
        </b-button>
        <b-button
          variant="outline-info"
          class="w-100 px-0"
          :disabled="!changed || saving"
          @click="resetData()"
        >
          <span>Restablecer</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputWithSkeleton from "../../components/Base/InputWithSkeleton.vue";
import BaseLiveSelect from "../../components/BaseLiveSelect.vue";
import BaseAlertWithCountDown from "../../components/Base/BaseAlertWithCountDown.vue";
import CREATE_MIGRATION_INFO from "../../graphql/MigrationInfo/CreateMigrationInfo.gql";
import UPDATE_MIGRATION_INFO from "../../graphql/MigrationInfo/UpdateMigrationInfo.gql";
import ADMIN_COMPANIES from "../../graphql/AdminCompanies.gql";

export default {
  name: "MigrationInfoEdit",
  props: {
    migration: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    InputWithSkeleton,
    BaseAlertWithCountDown,
    BaseLiveSelect
  },
  data() {
    return {
      dateStartMigration: null,
      dateEndMigration: null,
      comments: null,
      company: null,
      saving: false,
      error: false,
      errorMessage: null,
      showAlert: false,
      isLoadingMigration: false
    };
  },
  mounted() {},
  computed: {
    validation() {
      return {
        dateStartMigration: {
          valid:
            this.dateStartMigration?.length > 0 &&
            this.dateStartMigration <= this.dateEndMigration,
          invalidFeedback:
            "La fecha de inicio de la migración es obligatoria y debe ser menor o igual a la fecha de término de migración"
        },
        dateEndMigration: {
          valid:
            this.dateEndMigration?.length > 0 &&
            this.dateStartMigration <= this.dateEndMigration,
          invalidFeedback:
            "La fecha de término de la migración es obligatoria y debe ser mayor o igual a la fecha de término de migración"
        }
      };
    },
    valid() {
      let valid = true;
      Object.keys(this.validation).forEach(x => {
        valid = valid && this.validation[x].valid;
      });
      return valid;
    },
    changed() {
      return (
        (this.migration?.dateStartMigration || "") != this.dateStartMigration ||
        (this.migration?.dateEndMigration || "") != this.dateEndMigration ||
        (this.migration?.comments || "") != this.comments ||
        (this.migration?.company?.id || null) != this.company?.value
      );
    },
    alertVariant() {
      return this.error ? "danger" : "success";
    },
    alertMessage() {
      return this.error
        ? "Ha ocurrido un error guardando los datos: " + this.errorMessage
        : "Migración guardada exitosamente";
    }
  },
  methods: {
    /**
     * Indica si es creacion o no
     * @return {Boolean}
     */
    isConsolidated() {
      if (this.$route.name == "MigrationInfoNew") {
        return false;
      } else {
        return true;
      }
    },
    async save() {
      this.error = false;
      this.errorMessage = null;
      if (!this.valid) {
        this.error = true;
        this.errorMessage =
          "Hay campos que se deben rellenar o hay error en las fechas ingresadas.";
        this.showAlert = true;
      } else {
        this.saving = true;
        let action = "updated";
        if (this.isConsolidated()) {
          await this.updateMigration();
        } else {
          await this.createMigration();
          action = "created";
        }
        if (!this.error) {
          this.$router.push({
            name: "MigrationInfos",
            params: {
              action: action
            }
          });
        }
      }
    },
    /**
     * Envia la mutacion para actualizar una migración
     */
    async updateMigration() {
      await this.$apollo
        .mutate({
          mutation: UPDATE_MIGRATION_INFO,
          variables: {
            migrationInfo: this.getVariables()
          }
        })
        .then(({ data }) => {
          if (!data.updateMigrationInfo.result) {
            this.error = true;
            this.errorMessage = data.updateMigrationInfo.error;
            this.showAlert = true;
          } else {
            this.migration.dateStartMigration = this.dateStartMigration;
            this.migration.dateEndMigration = this.dateEndMigration;
            this.migration.comments = this.comments;
          }
          this.saving = false;
        })
        .catch(e => {
          this.error = true;
          this.errorMessage = e.message;
          this.showAlert = true;
          this.saving = false;
        });
    },
    /**
     * Envia la mutacion para crear una migración
     */
    async createMigration() {
      await this.$apollo
        .mutate({
          mutation: CREATE_MIGRATION_INFO,
          variables: {
            migrationInfo: this.getVariables(),
            companyId: this.getCompanyId()
          }
        })
        .then(({ data }) => {
          if (!data.createMigrationInfo.result) {
            this.error = true;
            this.errorMessage = data.createMigrationInfo.error;
          }
          this.showAlert = true;
          this.saving = false;
        })
        .catch(e => {
          this.error = true;
          this.errorMessage = e.message;
          this.showAlert = true;
          this.saving = false;
        });
    },
    /**
     * Se encarga de filtrar las empresas a mostrar como opciones del select
     * solicitando al servidor un subconjunto que contenga en su nombre el
     * término ingresado por el usuario.
     * @param {String} search término a ser buscado
     * @param {Boolean} loading indica si el request está en curso
     * @param {Function} setOptions función del componente BaseLiveSelect que
     *                              espera recibir un arreglo de opciones.
     */
    async updateCompanies(search, loading, setOptions) {
      loading(true);
      this.$apollo
        .query({
          query: ADMIN_COMPANIES,
          variables: { name: search }
        })
        .then(async result => {
          if (result?.data?.adminCompanies?.edges) {
            setOptions(
              result.data.adminCompanies.edges.map(x => {
                return this.itemForSelect(x.node);
              })
            );
          }
          loading(false);
        })
        .catch(() => {
          loading(false);
        });
    },
    /**
     * Transforma un elemento obtenido desde Centry para que sea un item dentro
     * del listado de opciones que maneja el componente BaseLiveSelect.
     *
     * El resultado es un objeto con la siguiente estructura:
     * `{ value: "id", label: "nombre" }`
     *
     * @param {Object} item un objeto de Centry. Se espera que tenga `id` y
     *                      `name`.
     */
    itemForSelect(item) {
      if (item) {
        return { value: item.id, label: item.name };
      }
      return null;
    },
    /**
     * Se encarga de resetear los valores del formulario
     */
    resetData() {
      this.dateStartMigration = this.migration?.dateStartMigration || "";
      this.dateEndMigration = this.migration?.dateEndMigration || "";
      this.comments = this.migration?.comments || "";
      this.company = {
        value: this.migration?.company?.id,
        label: this.migration?.company?.name
      };
    },
    getVariables() {
      let toUpdate = {};
      if (this.isConsolidated()) {
        toUpdate["id"] = this.migration.id;
      }
      toUpdate["dateStartMigration"] = this.dateStartMigration;
      toUpdate["dateEndMigration"] = this.dateEndMigration;
      toUpdate["comments"] = this.comments;
      return toUpdate;
    },
    getCompanyId() {
      if (this.company != null) {
        return this.company.value;
      } else {
        return null;
      }
    }
  },
  watch: {
    migration: {
      handler(val) {
        if (!val) {
          val = {};
        }
        this.dateStartMigration = val.dateStartMigration || "";
        this.dateEndMigration = val.dateEndMigration || "";
        this.comments = val.comments || "";
        if (val.company?.id != null) {
          this.company = { value: val.company.id, label: val.company.name };
        }
      },
      deep: true
    }
  }
};
</script>

<style></style>
