<template>
  <div>
    <base-header
      :titleSize="0"
      title="Informacion de migraciones"
    ></base-header>
    <base-alert-with-count-down
      v-model="showAlert"
      alert-variant="success"
      :dismiss-secs="error ? 10 : 5"
    >
      {{ alertMessage }}
    </base-alert-with-count-down>
    <migration-info-table />
  </div>
</template>

<script>
import BaseHeader from "../../components/BaseHeader.vue";
import MigrationInfoTable from "./MigrationInfoTable.vue";
import BaseAlertWithCountDown from "../../components/Base/BaseAlertWithCountDown.vue";
export default {
  name: "MigrationInfoIndex",
  components: {
    BaseHeader,
    MigrationInfoTable,
    BaseAlertWithCountDown
  },
  props: {
    action: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showAlert: this.action ? true : false,
      error: false,
      errorMessage: null
    };
  },
  computed: {
    alertVariant() {
      return this.error ? "danger" : "success";
    },
    alertMessage() {
      if (this.action == "created") {
        return "Migración creada correctamente";
      } else {
        return "Migración actualizada exitosamente";
      }
    }
  },
  watch: {
    action(val) {
      this.showAlert = val ? true : false;
    }
  }
};
</script>

<style scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
