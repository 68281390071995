<template v-slot:actions>
  <div>
    <template>
      <b-button
        v-if="currentUser.role === 0"
        :to="'/admin/migration_info/new'"
        variant="outline-info"
        class="mb-2"
      >
        <b-icon-plus></b-icon-plus>
        Nueva migración
      </b-button>
    </template>
    <b-container fluid="">
      <b-row>
        <b-col md="12">
          <span v-if="currentUser.role === 0"
            >* Solo puede haber una empresa asociada a cada migración</span
          >
          <migration-info-list />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import MigrationInfoList from "../../components/MigrationInfo/MigrationInfoList.vue";
import { mapState } from "vuex";
export default {
  name: "MigrationInfoTable",
  components: {
    MigrationInfoList
  },
  computed: {
    ...mapState(["currentUser"])
  }
};
</script>

<style></style>
