<template>
  <b-navbar-nav>
    <b-nav-item href="#" v-b-modal.modal-detail>
      Quedan {{ daysRemaining }} días para migrar a Anymarket
    </b-nav-item>
    <b-modal
      id="modal-detail"
      ref="modal-detail"
      title="Migración de Centry a Anymarket"
      ok-only
      ok-title="Entendido"
    >
      <p>
        Tenemos buenas noticias para ti, ya tienes programada una fecha para
        migrar tu cuenta a Anymarket. Esta es:
      </p>
      <p class="highlight">
        {{ migrationInfo.dateStartMigration | formatDate }}
      </p>
      <p>Para más información, por favor visita el siguiente enlace:</p>
      <p>
        <a
          href="https://centry2any.freshdesk.com/es-LA/support/solutions/articles/151000200001-proceso-de-migración1212"
          target="_blank"
          >https://centry2any.freshdesk.com/es-LA/support/solutions/articles/151000200001-proceso-de-migración1212</a
        >
      </p>
    </b-modal>
  </b-navbar-nav>
</template>
<script>
export default {
  name: "TheMenuMigrationInfo",
  props: {
    migrationInfo: Object
  },
  computed: {
    daysRemaining() {
      let date = new Date(this.migrationInfo.dateStartMigration);
      let now = new Date();
      let diff = date - now;
      let days = Math.floor(diff / (1000 * 60 * 60 * 24));
      return days;
    }
  },
  mounted() {
    this.$refs["modal-detail"].show();
  }
};
</script>

<style scoped>
.highlight {
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
}
</style>
