<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="info">
      <the-menu-brand :current-user="user"></the-menu-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <the-menu-sections
          :user-group="userGroup"
          :sections="sections"
          :current-user="user"
        ></the-menu-sections>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <the-menu-migration-info
            v-if="user.company.migrationInfo"
            :migration-info="user.company.migrationInfo"
          />
          <b-nav-item-dropdown
            :text="user.firstName"
            right
            class="font-weight-bolder"
          >
            <b-dropdown-item
              v-for="(userMenu, index) of allowedUserMenues"
              :to="userMenu.path[0] == '/' ? userMenu.path : ''"
              :href="userMenu.path[0] != '/' ? userMenu.path : ''"
              :key="index"
              >{{ userMenu.label }}</b-dropdown-item
            >
            <b-dropdown-divider></b-dropdown-divider>
            <div v-if="user.role === 0">
              <b-dropdown-item disabled=""
                >Herramientas de administrador</b-dropdown-item
              >
              <b-dropdown-item
                v-for="(adminMenu, index) of adminMenues"
                :to="adminMenu.path[0] == '/' ? adminMenu.path : ''"
                :href="adminMenu.path[0] != '/' ? adminMenu.path : ''"
                :key="index"
                >{{ adminMenu.label }}</b-dropdown-item
              >
              <b-dropdown-divider></b-dropdown-divider>
            </div>
            <ApolloMutation
              :mutation="require('../graphql/SignoutUser.gql')"
              @done="performLogOut"
            >
              <template slot-scope="{ mutate }">
                <b-dropdown-item @click="mutate">Salir</b-dropdown-item>
              </template>
            </ApolloMutation>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<script>
import { centryUrl } from "@/main";
import { mapState, mapMutations } from "vuex";
import { onLogout } from "@/vue-apollo";
import TheMenuSections from "./TheMenuSections";
import TheMenuBrand from "./TheMenuBrand";
import TheMenuMigrationInfo from "./TheMenuMigrationInfo";
export default {
  name: "TheMenu",
  components: {
    TheMenuSections,
    TheMenuBrand,
    TheMenuMigrationInfo
  },
  props: {
    tempUser: Object
  },
  data() {
    return {
      user: this.$dup(this.tempUser),
      adminMenues: [
        {
          label: "Configuración Carga Masiva",
          path: centryUrl + "/admin/bulkconf"
        },
        {
          label: "Empresas Registradas",
          path: centryUrl + "/admin/empresas"
        },
        {
          label: "Usuarios Registrados",
          path: centryUrl + "/admin/users"
        },
        {
          label: "Configuración Planes de Suscripción",
          path: centryUrl + "/admin/pago/empresas"
        },
        {
          label: "Códigos Promocionales",
          path: centryUrl + "/admin/payments/codigo_promocional"
        },
        {
          label: "Configuración Tags Mensajes",
          path: centryUrl + "/admin/message_tags"
        },
        {
          label: "Aplicaciones",
          path: centryUrl + "/admin/public_applications"
        },
        {
          label: "Integraciones",
          path: centryUrl + "/admin/integraciones"
        },
        {
          label: "Desarrolladores de Aplicaciones Externas",
          path: centryUrl + "/admin/desarrolladores"
        },
        {
          label: "Log",
          path: centryUrl + "/admin/log?lines=200"
        },
        {
          label: "Traducción de Errores",
          path: centryUrl + "/admin/error_translations"
        },
        {
          label: "Videotutoriales por Secciones",
          path: centryUrl + "/admin/sections"
        },
        {
          label: "Monitoreo de sincronizaciones",
          path: "/admin/synchronization_monitoring"
        },
        {
          label: "Cargas masivas pendientes",
          path: "/admin/pending_bulk_upload"
        },
        {
          label: "Monitor de colas de tareas asíncronas",
          path: "/admin/sidekiq_queue_monitor"
        },
        {
          label: "Tareas asíncronas programadas",
          path: "/admin/sidekiq_cron"
        },
        {
          label: "Mantenedor Banners",
          path: "/admin/banners"
        },
        {
          label: "Mensajes programados",
          path: "/admin/messages_scheduled"
        },
        {
          label: "Comunicaciones de migración",
          path: "/admin/migration_infos"
        }
      ]
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    userGroup() {
      return this.$userGroup(this.user);
    },
    allowedUserMenues() {
      if (!this.userGroup || this.currentUser.role === 0) {
        return this.userMenues;
      }
      return this.userMenues.filter(
        x =>
          typeof x.permission === "undefined" ||
          this.userGroup[x.permission] > 0
      );
    },
    sections() {
      return this.user.company.betaSectionsAllowed.map(x => {
        return x.section;
      });
    },
    userMenues() {
      return [
        {
          label: "Mi Perfil",
          path: "/profile/" + this.user.id + "/edit"
        },
        {
          label: "Mi Empresa",
          path: "/company/" + this.user.company.id,
          permission: "integrations"
        },
        {
          label: "Mi Suscripción",
          path: centryUrl + "/historial"
        },
        {
          label: "Usuarios",
          path: "/users",
          permission: "users"
        },
        {
          label: "Alertas",
          path: centryUrl + "/alertas",
          permission: "alerts"
        },
        {
          label: "Bloqueos",
          path: "/blockages",
          permission: "blockages"
        },
        {
          label: "Api & Apps",
          path: centryUrl + "/oauth/applications",
          permission: "applications"
        },
        {
          label: "Ayuda",
          path: "https://manual.centry.cl"
        }
      ];
    }
  },
  methods: {
    ...mapMutations(["setCurrentUser"]),
    async performLogOut() {
      await onLogout(this.$apollo.getClient());
      await this.setCurrentUser(null);
      window.location.href = centryUrl;
    }
  },
  watch: {
    tempUser: {
      handler(val) {
        this.user = val;
      },
      deep: true
    }
  }
};
</script>
<style lang="scss" scoped>
$navbarBg: #455a64;
$navbarBgHover: #607d8b;

.navbar {
  background-color: $navbarBg !important;
}
</style>
