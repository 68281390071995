<template>
  <v-select
    v-model="value"
    @input="emitChange"
    :id="id"
    :clearable="clearable"
    :placeholder="placeholder"
    :options="options"
    :multiple="multiple"
    :disabled="disabled"
    @search="updateOptions"
    :appendToBody="appendToBody"
  >
    <template v-slot:no-options="{ search, loading }">
      <b-spinner v-if="loading" label="Spinning"></b-spinner>
      <p v-if="search.length >= minSearchCharacters">
        No hay opciones disponibles
      </p>
      <p v-else>
        Escriba al menos {{ minSearchCharacters }} caracter{{
          minSearchCharacters > 1 ? "es" : ""
        }}
      </p>
    </template>
  </v-select>
</template>
<script>
export default {
  name: "BaseLiveSelect",
  model: {
    prop: "_value",
    event: "change"
  },
  props: {
    _value: {
      type: [Object, Array],
      default() {
        return null;
      }
    },
    id: String,
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    minSearchCharacters: {
      type: Number,
      default: 3
    },
    multiple: {
      type: Boolean,
      default: false
    },
    debounce: {
      type: Number,
      default: 0
    },
    appendToBody: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: this._value,
      options: [],
      timeout: null
    };
  },
  methods: {
    /**
     * Emite un evento indicando que hubo un cambio
     */
    emitChange() {
      this.$emit("change", this.value);
    },
    /**
     * Se encarga de emitir un evento para actualizar las opciones
     * @param {String} search
     * @param {Function} loading
     */
    updateOptions(search, loading) {
      loading(true);
      if (search.length >= this.minSearchCharacters) {
        if (this.debounce > 0) {
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.emitSearch(search, loading);
          }, this.debounce);
        } else {
          this.emitSearch(search, loading);
        }
      } else {
        this.options = [];
        if (this.timeout) clearTimeout(this.timeout);
        loading(false);
      }
    },
    /**
     * Actualiza el listado de opciones
     * @param {Array} options
     */
    setOptions(options) {
      this.options = options;
    },
    /**
     * Emite el evento indicando que se agrego algo al input
     * @param {String} search
     * @param {Function} loading
     */
    emitSearch(search, loading) {
      this.$emit("search", search, loading, this.setOptions);
    }
  },
  watch: {
    _value(newVal) {
      this.value = newVal;
    }
  }
};
</script>
