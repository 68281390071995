<template>
  <b-tr>
    <b-td>
      {{ migration.dateStartMigration }}
    </b-td>
    <b-td>
      {{ migration.dateEndMigration }}
    </b-td>
    <b-td>
      {{ migration.company.name }}
    </b-td>
    <b-td>
      <b-row align-h="end" no-gutters>
        <b-button
          v-if="currentUser.role === 0"
          size="lg"
          variant="outlined"
          class="p-0 focus-btn"
          v-b-tooltip.hover="'Editar Migración'"
          :to="`/admin/migration_info/${migration.id}/edit`"
        >
          <b-icon stacked icon="pencil" scale="0.60"></b-icon>
        </b-button>
        <b-button
          v-if="currentUser.role === 0"
          size="lg"
          variant="outlined"
          class="p-0 focus-btn"
          v-b-tooltip.hover="'Eliminar Migración'"
          @click="swalDeleteMigrationInfo"
        >
          <b-icon stacked icon="x" scale="0.75"></b-icon>
        </b-button>
      </b-row>
    </b-td>
  </b-tr>
</template>

<script>
import DELETE_MIGRATION from "../../graphql/MigrationInfo/DeleteMigrationInfo.gql";
import { mapState } from "vuex";
export default {
  name: "MigrationInfoDetail",
  props: {
    migration: Object
  },
  data() {
    return {
      deleting: false,
      updating: false
    };
  },
  computed: {
    ...mapState(["currentUser"])
  },
  methods: {
    /**
     * Se encarga de enviar la mutacion para borrar una migración
     */
    deleteMigrationInfo() {
      this.deleting = true;
      this.$apollo
        .mutate({
          mutation: DELETE_MIGRATION,
          variables: {
            id: this.migration.id
          }
        })
        .then(({ data }) => {
          if (data.deleteMigrationInfo.result) {
            this.$emit("delete-migration");
            this.deleting = false;
          } else {
            this.$emit("delete-migration", data.deleteMigrationInfo.error);
            this.deleting = false;
          }
        });
    },
    /**
     * Lanza un swal para confirmar la eliminacion del banner
     */
    swalDeleteMigrationInfo() {
      this.$swal
        .fire({
          title: "Eliminar Migración",
          text: "La migración se eliminará de forma permanente. ¿Estás seguro?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No"
        })
        .then(result => {
          if (result.value) {
            this.deleteMigrationInfo();
          }
        });
    }
  }
};
</script>

<style></style>
